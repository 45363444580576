import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import SubToggle from '../sub-toggle';
import {
  Box,
  CircularProgress,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import {strings} from '@app/common/strings';
import {Fragment, useEffect, useState} from 'react';
import {useStyles} from '@app/screens/zho/pages/fc-admin/request-form/styles';
import {
  fetchSummaryTableDataCreator,
  hoDashboardActions,
} from '../../pages/ho-admin/redux-api/slice';
import {updateSummaryPayload} from '../../pages/ho-admin/redux/orderStore';
import chemist from '@app/assets/images/chemist.png';

const TableSummary = ({userLoginInfo}: any) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [isMounted, setIsMounted] = useState(false);

  const summaryPayload = useSelector(
    (state: RootStateOrAny) => state.hoOrderStore.value.summaryPayload,
    
  );

  const summaryTableData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.summaryTableData,
  );

  const summaryTableDataLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.summaryTableLoading,
  );

  const currentSubTab = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.subTabSwitch,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    document.body.style.overflow = 'auto';

    dispatch(
      fetchSummaryTableDataCreator(
        {
          ...summaryPayload,
          division:
            summaryPayload.division.length === 0
              ? [userLoginInfo?.division[0]?.id]
              : summaryPayload.division,
          currentStaffPositionId: userLoginInfo?.staffPositionId,
        },
        currentSubTab - 1,
      ),
    );
    setIsMounted(true);
    return () => {
      dispatch(hoDashboardActions.setSummaryTableData([]));
      dispatch(hoDashboardActions.setSummaryTotalData([]));
    };
  }, []);

  useEffect(() => {
    if (isMounted) {
      dispatch(
        fetchSummaryTableDataCreator(
          {
            ...summaryPayload,
            currentStaffPositionId: userLoginInfo?.staffPositionId,
            division:
              summaryPayload.division.length === 0
                ? [userLoginInfo?.division[0]?.id]
                : summaryPayload.division,
          },
          currentSubTab - 1,
        ),
      );
    }
  }, [currentSubTab]);

  const classes = useStyles();

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
    dispatch(
      updateSummaryPayload({
        ...summaryPayload,
        currentStaffPositionId: userLoginInfo?.staffPositionId,
        pageSize: rowsPerPage,
        pageNumber: newPage,
      }),
    );

    dispatch(
      fetchSummaryTableDataCreator(
        {
          ...summaryPayload,
          division:
            summaryPayload.division.length === 0
              ? [userLoginInfo?.division[0]?.id]
              : summaryPayload.division,
          pageSize: rowsPerPage,
        },
        currentSubTab - 1,
      ),
    );
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const newPageSize = parseInt(event.target.value, 10);
    setRowsPerPage(newPageSize);
    setPage(0);

    dispatch(
      fetchSummaryTableDataCreator(
        {
          ...summaryPayload,
          division:
            summaryPayload.division.length === 0
              ? [userLoginInfo?.division[0]?.id]
              : summaryPayload.division,
          pageNumber: 0,
          pageSize: newPageSize,
        },
        currentSubTab - 1,
      ),
    );

    dispatch(
      updateSummaryPayload({
        ...summaryPayload,
        currentStaffPositionId: userLoginInfo?.staffPositionId,
        pageSize: newPageSize,
        pageNumber: 0,
      }),
    );
  };

  return (
    <>
      <SubToggle
        title1="Chemist/Dispenser"
        title2="Customer"
        title3="Products"
      />

      <Container
        sx={{
          marginTop: '10px',
          position: 'relative',
          maxWidth: '100% !important',
          padding: '0px 20px  !important',
        }}>
        <TableContainer
          sx={{maxHeight: '330px'}}
          className={`${classes.noBoxShd}`}
          component={Paper}>
          <Table sx={{borderCollapse: 'separate', borderSpacing: '0px 1px'}}>
            <TableHead>
              <TableRow className={`${classes.requestformTableHeading}   `}>
                <TableCell
                  sx={{
                    border: 'none',
                    padding: '0.2rem 0.5rem',
                    paddingLeft: '1rem',
                    width: '45%',
                    textTransform: 'uppercase',
                  }}
                  className={`${classes.requestformTableHeading}`}>
                  {currentSubTab === 1
                    ? strings.zho.fcAdmin.dashboard.tableHeading.chemistandDis
                    : currentSubTab === 2
                    ? strings.zho.customer
                    : strings.zho.products}
                </TableCell>
                <TableCell
                  sx={{border: 'none'}}
                  className={`${classes.requestformTableHeading} summaryTableBgHeading summaryTableBtl `}>
                  {strings.zho.fcAdmin.summary.tableHeading.totalOrders}
                </TableCell>
                <TableCell
                  sx={{border: 'none'}}
                  className={`${classes.requestformTableHeading} summaryTableBgHeading `}>
                  {strings.zho.fcAdmin.summary.tableHeading.salesValue}
                </TableCell>
                <TableCell
                  sx={{border: 'none'}}
                  className={`${classes.requestformTableHeading} summaryTableBgHeading summaryTableBtr `}>
                  {strings.zho.fcAdmin.summary.tableHeading.avgExtraDis}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!summaryTableDataLoading ? (
                summaryTableData?.orders?.length > 0 ? (
                  summaryTableData?.orders?.map((data: any, index: number) => {
                    return (
                      <Fragment key={index}>
                        <TableRow className={classes.requestformTbleBoxSdw}>
                          <TableCell
                            className={` ${classes.checkBoxShd}  ${classes.requestFormTableFont} ${classes.productTableLeftBorder} ${classes.borderTopLeft} `}>
                            <Stack
                              direction="row"
                              justifyContent="flex-start"
                              alignItems="center"
                              gap={1.5}>
                              {currentSubTab === 1 && (
                                <Box>
                                  <img
                                    src={chemist}
                                    height="30px"
                                    width="30px"
                                  />
                                </Box>
                              )}

                              <Box>
                                {currentSubTab === 1
                                  ? data?.partyName
                                  : currentSubTab === 2
                                  ? data?.stockistName
                                  : data?.productName}
                              </Box>
                            </Stack>
                          </TableCell>
                          <TableCell
                            align="center"
                            className={` ${classes.cellPadding}  ${classes.requestFormTableFont}`}>
                            {data?.totalOrders}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              padding: '0px !important',
                              wordBreak: 'break-all !important',
                            }}
                            className={` ${classes.requestFormTableFont}`}>
                            {data?.totalInvoiceCost}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{padding: '0px !important'}}
                            className={`  ${classes.requestFormTableFont}`}>
                            {data?.totalPercentage}%
                          </TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell sx={{textAlign: 'center'}} colSpan={9}>
                      Currently there are no data!
                    </TableCell>
                  </TableRow>
                )
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <Box
                      className="loader"
                      sx={{display: 'flex', justifyContent: 'center', mt: 3}}>
                      <CircularProgress />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[10, 20, 50, 100]}
          count={+summaryTableData?.totalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Container>
    </>
  );
};

export default TableSummary;
