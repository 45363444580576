import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from '@mui/material';

import React, {useCallback, useEffect, useState} from 'react';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';

import {Container, Stack} from '@mui/system';
import {strings} from '@app/common/strings';
import {updateTotalTradeDiscountStyles as styles} from '../styles';
import {Label} from '@app/components/elements/Label';
import {
  fetchProductListFromTotalDiscountDataCreator,
  fetchProductListFromTotalDiscountFilterDataCreator,
  fetchUpdateProductTotalDiscountDataCreator,
} from '../../redux-api/slice';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {gridStyles} from '@app/screens/zho/components/widgets/DataGrid/styles';
import {useStyles as tableClasses} from '@app/screens/zho/components/grid-table/styles';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {CloseOutlined, InfoOutlined} from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import {Dayjs} from 'dayjs';
import {ConfirmationModal} from './confirmationModal';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Modal from 'react-modal';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Product {
  productId: number;
  productName: string;
  productList?: Array<any>;
}

interface ProductData {
  divisionId: number;
  divisionName: string;
  productList: Product[] | [];
}

export const ProductListing = ({userLoginInfo}: any) => {
  const tableStyles = tableClasses();
  const dispatch = useDispatch();
  const preventDefaultHandleBill = useCallback(e => e.preventDefault(), []);
  const renderInputBill = useCallback(function (params: any) {
    return (
      <TextField
        {...params}
        helperText={null}
        onKeyDown={preventDefaultHandleBill}
        size="small"
        sx={styles.dateInput}
      />
    );
  }, []);
  const divisionData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.divisionData,
  );
  const productFilterData = useSelector(
    (state: RootStateOrAny) =>
      state.fcDashBoard.productListFromTotalDiscountFilterData,
  );
  const productData = useSelector(
    (state: RootStateOrAny) =>
      state.fcDashBoard.productListFromTotalDiscountData,
  );
  const discountUpdated = useSelector(
    (state: RootStateOrAny) =>
      state.fcDashBoard.updateProductTotalDiscountCompleted,
  );

  const [selectedDivisionId, setSelectedDivisionId] = useState<number | null>(
    null,
  );
  const [productListData, setProductListData] = useState<ProductData | null>(
    null,
  );
  const [filter, setFilter] = useState<Product[] | null>(null);
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const [editState, setEditState] = useState<{
    id: number | null;
    additionalDiscount: number | null;
    validFrom: Dayjs | null;
    monthlyQuantity: number | null;
    dailyQuantity: number | null;
    currentUserId: number | null;
  }>({
    id: null,
    additionalDiscount: null,
    validFrom: null,
    monthlyQuantity: null,
    dailyQuantity: null,
    currentUserId: null,
  });
  const [modalOpen, setModalOpen] = useState<{
    id: number | null;
    isOpen: boolean;
  }>({id: null, isOpen: false});
  const [deleteModal, setDeleteModal] = useState<{
    id: number | null;
    isOpen: boolean;
    filterProductId: number | null;
  }>({id: null, isOpen: false, filterProductId: null});

  const [isFocused, setIsFocused] = useState<{
    focus: boolean;
    id: number | null;
  }>({
    focus: false,
    id: null,
  });

  const handleInputFocus = (id: number) => {
    setIsFocused({
      focus: true,
      id,
    });
  };

  const handleInputBlur = () => {
    setIsFocused({
      focus: false,
      id: null,
    });
  };

  const handleDeleteToggle = (id?: number, filterProductId?: number) => {
    setDeleteModal({
      id: id || null,
      isOpen: !deleteModal.isOpen,
      filterProductId: filterProductId || null,
    });
  };

  const LightTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} />
  ))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px;',
      fontSize: 11,
      border: `1px solid #322b7c `,
      borderRadius: '15px',
      padding: '10px',
    },
  }));

  const handleTooltipOpen = (index: number) => {
    setModalOpen({id: index, isOpen: true});
  };
  const handleTooltipClose = (index: number) => {
    setModalOpen({id: index, isOpen: false});
  };

  const handleEditToggle = (data: any) => {
    const date = new Date();
    const dateWithoutTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    );

    if (data?.productList[0]?.id === editState?.id) {
      setEditState({
        id: null,
        additionalDiscount: null,
        validFrom: null,
        monthlyQuantity: null,
        dailyQuantity: null,
        currentUserId: null,
      });
    } else if (new Date(data?.productList[0].validFrom) < dateWithoutTime) {
      setEditState(prevState => ({
        ...prevState,
        currentUserId: userLoginInfo?.id,
        ...data?.productList[0],
        validFrom: new Date().toISOString(),
      }));
    } else {
      setEditState(prevState => ({
        ...prevState,
        currentUserId: userLoginInfo?.id,
        ...data?.productList[0],
      }));
    }
  };

  const handleDivisionChange = (divisionId: number) => {
    setSelectedProducts([]);
    setSelectedDivisionId(divisionId);
    dispatch(fetchProductListFromTotalDiscountFilterDataCreator(divisionId));
  };

  const handleProductSelect = (value: Product[]) => {
    const ids: number[] = [];
    for(let h=0;h<value.length;h++){
      ids.push(value[h].productId);
    }
    setSelectedProducts(ids);
  };

  const handleGoButtonClick = () => {
    const filterProductIds = filter?.map(
      (product: Product) => product.productId,
    );

    const validSelectedProducts = selectedProducts.filter(productId =>
      filterProductIds?.includes(Number(productId)),
    );
    const payload = {
      divisionId: selectedDivisionId,
      productId: validSelectedProducts,
    };
    dispatch(fetchProductListFromTotalDiscountDataCreator(payload));
  };

  const percentageInputValidation = (e: any) => {
    let inputValue = e.target.value;
    const cursorPosition = e.target.selectionStart;
    const previousValue = e.target.value;
    inputValue = inputValue.replace(/[^0-9.]/g, '');
    const parts = inputValue.split('.');
    if (parts[0].length > 3) {
      parts[0] = parts[0].slice(0, 3);
    }
    if (parts.length > 1) {
      parts[1] = parts[1].slice(0, 2);
      inputValue = parts[0] + '.' + parts[1];
    } else {
      inputValue = parts[0];
    }
    e.target.value = inputValue;
    let newCursorPosition = cursorPosition;
    if (previousValue.length > inputValue.length) {
      newCursorPosition -= previousValue.length - inputValue.length;
    } else if (previousValue.length < inputValue.length) {
      newCursorPosition += inputValue.length - previousValue.length;
    }
    setTimeout(
      () => e.target.setSelectionRange(newCursorPosition, newCursorPosition),
      0,
    );
  };

  const handleChange = (value: any, name: string) => {
    setEditState(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSave = () => {
    if (
      editState.additionalDiscount !== null &&
      editState.additionalDiscount > 0 &&
      editState.additionalDiscount < 100 &&
      (editState.dailyQuantity === null || editState.dailyQuantity > 0) &&
      (editState.monthlyQuantity === null || editState.monthlyQuantity > 0)
    ) {
      const initialState = findNestedProductById(editState.id);
      if (
        initialState.additionalDiscount !== editState.additionalDiscount ||
        initialState.dailyQuantity !== editState.dailyQuantity ||
        initialState.monthlyQuantity !== editState.monthlyQuantity ||
        initialState.validFrom !== editState.validFrom
      ) {
        dispatch(fetchUpdateProductTotalDiscountDataCreator(editState));
      } else {
        setEditState({
          id: null,
          additionalDiscount: null,
          validFrom: null,
          monthlyQuantity: null,
          dailyQuantity: null,
          currentUserId: null,
        });
      }
    }
  };

  const findNestedProductById = (id: number | null) => {
    if (!productListData || !Array.isArray(productListData.productList)) {
      return null;
    }
    for (const product of productListData.productList) {
      const foundProduct = product?.productList?.find(
        nestedProduct => nestedProduct.id === id,
      );
      if (foundProduct) {
        return foundProduct;
      }
    }
    return null;
  };

  const removeDeletedProducts = (id: number) => {
    setFilter(prev => {
      if (prev === null) return null;
      return prev.filter(item => item.productId !== id);
    });
  };

  const formatDate = (date: Date): string => {
    const year = new Date(date).getFullYear();
    const month = (new Date(date).getMonth() + 1).toString().padStart(2, '0');
    const day = new Date(date).getDate().toString().padStart(2, '0');
    return `${day}/${month}/${year}`;
  };

  useEffect(() => {
    if (divisionData.length > 0) {
      setSelectedDivisionId(divisionData[0].divisionId);
      dispatch(
        fetchProductListFromTotalDiscountFilterDataCreator(
          divisionData[0].divisionId,
        ),
      );
      const payload = {
        divisionId: divisionData[0].divisionId,
        productId: selectedProducts,
      };
      dispatch(fetchProductListFromTotalDiscountDataCreator(payload));
    }
  }, [divisionData]);

  useEffect(() => {
    setFilter(productFilterData);
  }, [productFilterData]);

  useEffect(() => {
    if (productData?.productList !== undefined) {
      setProductListData(productData);
    }
  }, [productData]);

  useEffect(() => {
    if (selectedDivisionId !== null) {
      setEditState({
        id: null,
        additionalDiscount: null,
        validFrom: null,
        monthlyQuantity: null,
        dailyQuantity: null,
        currentUserId: null,
      });
      const payload = {
        divisionId: selectedDivisionId,
        productId: selectedProducts,
      };
      dispatch(fetchProductListFromTotalDiscountDataCreator(payload));
    }
  }, [discountUpdated]);

  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
  };
  const enableScroll = () => {
    document.body.style.overflow = 'auto';
  };

  const columns: GridColDef[] = [
    {
      field: 'productId',
      headerName: 'Product ID',
      headerClassName: 'boldHeader',
      sortable: false,
      flex: 1,
      minWidth: 90,
      renderCell: params => (
        <div className={tableStyles.tableCell}>
          <Box className={tableStyles.cellContent}>
            <Label title={params.row.productId} hideToolTip />
          </Box>
        </div>
      ),
    },
    {
      field: 'productName',
      headerName: 'Product Name',
      headerClassName: 'boldHeader',
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: params => (
        <div className={tableStyles.tableCell}>
          <Box className={tableStyles.cellContent} sx={{cursor: 'default'}}>
            <Label title={params.row.productName} />
          </Box>
        </div>
      ),
    },
    {
      field: 'standardDiscount',
      headerName: 'Standard Discount %',
      headerClassName: 'boldHeader',
      sortable: false,
      flex: 1,
      minWidth: 90,
      renderHeader: params => (
        <Typography sx={styles.listingTableHeader}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: params => (
        <div className={tableStyles.tableCell}>
          <Box className={tableStyles.cellContent}>
            <Label
              title={params.row.productList[0]?.standardDiscount}
              hideToolTip
            />
          </Box>
        </div>
      ),
    },
    {
      field: 'additionalDiscount',
      headerName: 'Additional Discount %',
      headerClassName: 'boldHeader',
      sortable: false,
      flex: 1,
      minWidth: 95,
      renderHeader: params => (
        <Typography sx={[styles.listingTableHeader, styles.requiredHeader]}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: params => (
        <div className={tableStyles.tableCell}>
          <Box className={tableStyles.cellContent}>
            {editState.id === params.row.productList[0]?.id ? (
              <TextField
                data-testid="edit-additional-discount"
                type="text"
                error={
                  editState.additionalDiscount === null ||
                  editState.additionalDiscount <= 0 ||
                  editState.additionalDiscount >= 100
                }
                onInput={(e: any) => {
                  percentageInputValidation(e);
                }}
                sx={[styles.listingInput, styles.hideSpinButton]}
                InputProps={{
                  style: {
                    minWidth: '60px',
                    maxWidth: '100px',
                    height: '35px',
                    fontSize: '12.7px',
                  },
                }}
                defaultValue={editState.additionalDiscount}
                onChange={e =>
                  handleChange(
                    e.target.value !== '' && e.target.value[0] !== '.'
                      ? e.target.value
                      : null,
                    'additionalDiscount',
                  )
                }
              />
            ) : (
              <Box className={tableStyles.cellContent}>
                <Label
                  title={params.row.productList[0]?.additionalDiscount}
                  hideToolTip
                />
              </Box>
            )}
          </Box>
        </div>
      ),
    },
    {
      field: 'dailyQuantity',
      headerName: 'Daily Quantity',
      headerClassName: 'boldHeader',
      sortable: false,
      flex: 1,
      minWidth: 90,
      renderHeader: params => (
        <Typography sx={styles.listingTableHeader}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: params => (
        <div className={tableStyles.tableCell}>
          <Box className={tableStyles.cellContent}>
            {editState.id === params.row?.productList[0]?.id ? (
              <TextField
                error={
                  editState.dailyQuantity !== null &&
                  editState.dailyQuantity < 0
                }
                data-testid="edit-daily-quantity"
                type="number"
                sx={[styles.listingInput, styles.hideSpinButton]}
                InputProps={{
                  style: {
                    minWidth: '60px',
                    maxWidth: '100px',
                    height: '35px',
                    fontSize: '12.7px',
                  },
                }}
                defaultValue={editState.dailyQuantity}
                onInput={(e: any) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 7);
                }}
                onChange={e =>
                  handleChange(
                    e.target.value !== '' ? Number(e.target.value) : null,
                    'dailyQuantity',
                  )
                }
              />
            ) : (
              <Box className={tableStyles.cellContent}>
                <Label
                  title={params.row.productList[0]?.dailyQuantity || '--'}
                  hideToolTip
                />
              </Box>
            )}
          </Box>
        </div>
      ),
    },
    {
      field: 'monthlyQuantity',
      headerName: 'Monthly Quantity',
      headerClassName: 'boldHeader',
      sortable: false,
      flex: 1,
      minWidth: 90,
      renderHeader: params => (
        <Typography sx={styles.listingTableHeader}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: params => (
        <div className={tableStyles.tableCell}>
          <Box className={tableStyles.cellContent}>
            {editState.id === params.row?.productList[0]?.id ? (
              <TextField
                error={
                  editState.monthlyQuantity !== null &&
                  editState.monthlyQuantity < 0
                }
                data-testid="edit-monthly-quantity"
                type="number"
                sx={[styles.listingInput, styles.hideSpinButton]}
                InputProps={{
                  style: {
                    minWidth: '60px',
                    maxWidth: '100px',
                    height: '35px',
                    fontSize: '12.7px',
                  },
                }}
                defaultValue={editState.monthlyQuantity}
                onInput={(e: any) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 7);
                }}
                onChange={e =>
                  handleChange(
                    e.target.value !== '' ? Number(e.target.value) : null,
                    'monthlyQuantity',
                  )
                }
              />
            ) : (
              <Box className={tableStyles.cellContent}>
                <Label
                  title={params.row.productList[0]?.monthlyQuantity || '--'}
                  hideToolTip
                />
              </Box>
            )}
          </Box>
        </div>
      ),
    },
    {
      field: 'validFrom',
      headerName: 'Valid From',
      headerClassName: 'boldHeader',
      sortable: false,
      minWidth: 150,
      flex: 1,
      renderHeader: params => (
        <Typography sx={[styles.listingTableHeader, styles.requiredHeader]}>
          {params.colDef.headerName}
        </Typography>
      ),
      renderCell: params => (
        <div className={tableStyles.tableCell}>
          <Box className={tableStyles.cellContent}>
            {editState.id === params.row?.productList[0]?.id ? (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  // PopperProps={DialogProps}
                  disablePast={true}
                  views={['year', 'month', 'day']}
                  value={editState.validFrom}
                  onChange={date =>
                    handleChange(
                      date?.format('YYYY/MM/DDTHH:mm:ss'),
                      'validFrom',
                    )
                  }
                  renderInput={renderInputBill}
                />
              </LocalizationProvider>
            ) : (
              <Box className={tableStyles.cellContent}>
                <Label
                  hideToolTip
                  title={formatDate(params.row.productList[0]?.validFrom)}
                />
                {params.row.productList?.length > 1 && (
                  <ClickAwayListener
                    onClickAway={() =>
                      handleTooltipClose(params.row.productId)
                    }>
                    <LightTooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      onClose={() => handleTooltipClose(params.row.productId)}
                      open={
                        modalOpen.isOpen &&
                        modalOpen.id === params.row.productId
                      }
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title={
                        <Box position="relative">
                          <List sx={{padding: '0px'}}>
                            <ListItem
                              sx={{padding: '0px', marginBottom: '4px'}}>
                              Additional Discount -{' '}
                              {params.row.productList[1]?.additionalDiscount}
                            </ListItem>
                            <ListItem
                              sx={{padding: '0px', marginBottom: '4px'}}>
                              Daily Quantity -{' '}
                              {params.row.productList[1]?.dailyQuantity}
                            </ListItem>
                            <ListItem
                              sx={{padding: '0px', marginBottom: '4px'}}>
                              Monthly Quantity -{' '}
                              {params.row.productList[1]?.monthlyQuantity}
                            </ListItem>
                            <ListItem
                              sx={{padding: '0px', marginBottom: '4px'}}>
                              Valid From -{' '}
                              {formatDate(params.row.productList[1]?.validFrom)}
                            </ListItem>
                          </List>
                          <Box sx={styles.customTooltipIcon}>
                            <Stack
                              direction="row"
                              onClick={() =>
                                handleTooltipClose(params.row.productId)
                              }>
                              <CloseOutlined
                                sx={{
                                  width: '18px',
                                  height: '18px',
                                  cursor: 'pointer',
                                }}
                                color="primary"
                              />
                            </Stack>
                          </Box>
                        </Box>
                      }>
                      <InfoOutlined
                        data-testid="show-detailed-info"
                        onClick={() => handleTooltipOpen(params.row.productId)}
                        sx={{
                          height: '15px',
                          width: '15px',
                          marginLeft: '10px',
                          cursor: 'pointer',
                        }}
                        color="primary"
                      />
                    </LightTooltip>
                  </ClickAwayListener>
                )}
              </Box>
            )}
          </Box>
        </div>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      headerClassName: 'boldHeader',
      sortable: false,
      flex: 1,
      minWidth: 100,
      renderCell: params => (
        <div className={`${tableStyles.tableCell}  `}>
          <Stack spacing={1} direction="row">
            {editState.id === params.row?.productList[0]?.id ? (
              <>
                <Typography
                  data-testid="save-edited-discount"
                  sx={{cursor: 'pointer', fontSize: 12.7}}
                  onClick={handleSave}>
                  {strings.zho.save}
                </Typography>
                <Typography
                  data-testid="cancel-edited-discount"
                  sx={{cursor: 'pointer', fontSize: 12.7}}
                  onClick={() => handleEditToggle(params.row)}>
                  {strings.zho.cancel}
                </Typography>
              </>
            ) : (
              <Typography
                data-testid="modify-discount"
                sx={{cursor: 'pointer', fontSize: 12.7}}
                onClick={() => handleEditToggle(params.row)}>
                {strings.zho.modify}
              </Typography>
            )}
          </Stack>
        </div>
      ),
    },
    {
      field: ' ',
      headerClassName: 'boldHeader',
      sortable: false,
      flex: 1,
      maxWidth: 50,
      renderCell: params => (
        <div className={`${tableStyles.tableCell}  `}>
          <DeleteIcon
            data-testid="delete-product"
            sx={{color: '#AEB5B5', cursor: 'pointer'}}
            onClick={() =>
              handleDeleteToggle(
                params.row.productList[0].id,
                params.row.productId,
              )
            }
          />
        </div>
      ),
    },
  ];

  return (
    <>
      {/* Popup */}
      <div>
        <Modal
          isOpen={deleteModal.isOpen}
          onAfterOpen={disableScroll}
          onAfterClose={enableScroll}
          style={{
            content: {
              width: '320px',
              height: 'fit-content',
              margin: 'auto',
              right: 0,
              backgroundColor: '#ffffff',
              boxShadow: ' 0 4 #000000 26.7 0 25%',
              borderRadius: '8px',
              padding: '0px',
            },
            overlay: {zIndex: 1200, backgroundColor: '#0000003D'},
          }}>
          <ConfirmationModal
            id={deleteModal.id}
            handleToggleModal={handleDeleteToggle}
            divisionId={selectedDivisionId}
            productId={selectedProducts}
            removeDeletedProducts={id => removeDeletedProducts(id)}
            filterProductId={deleteModal.filterProductId}
          />
        </Modal>
      </div>
      {/* Popup */}

      <Stack
        bgcolor={'#f4f4f4'}
        padding={2}
        paddingLeft={3}
        marginBottom={2}
        spacing={3}
        alignItems="center"
        direction="row">
        <FormControl
          sx={[styles.productListingDropDown, styles.dropdownBgWhite]}>
          <InputLabel
            size="small"
            id="division"
            sx={{fontSize: '12.7px', color: '#1c1939B3'}}>
            {strings.zho.division}
          </InputLabel>
          <Select
            sx={[styles.productListingDropDown]}
            data-testId="division-dropdown"
            id="division"
            size="small"
            value={selectedDivisionId || ''}
            onChange={e => handleDivisionChange(Number(e.target.value))}
            label={strings.zho.division}>
            {divisionData?.map((division: any) => {
              return (
                <MenuItem
                  value={division?.divisionId}
                  sx={{fontSize: '12.7px'}}>
                  {division?.divisionName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {/* <FormControl
          sx={[styles.productListingDropDown, styles.dropdownBgWhite]}>
          <InputLabel
            size="small"
            sx={{fontSize: '12.7px', color: '#1c1939B3'}}
            id="product">
            {strings.zho.product}
          </InputLabel>
          <Select
            data-testId="product-dropdown"
            labelId="product"
            id="product"
            multiple
            size="small"
            value={selectedProducts}
            onChange={handleProductSelect}
            input={<OutlinedInput sx={{fontSize: '12.7px'}} label="Product" />}
            sx={[styles.productListingDropDown]}
            renderValue={selected => {
              const selectedNames = (selected as string[])
                .map(
                  value =>
                    filter?.find((product: any) => product.productId === value)
                      ?.productName,
                )
                .filter(Boolean);
              return selectedNames.length > 1
                ? `${selectedNames.length} products selected`
                : selectedNames;
            }}
            MenuProps={MenuProps}>
            {filter?.map((product: any) => (
              <MenuItem
                key={product?.productId}
                value={product?.productId}
                sx={{padding: '0px'}}>
                <Checkbox
                  checked={selectedProducts.indexOf(product?.productId) > -1}
                />
                <ListItemText
                  primary={product?.productName}
                  sx={{
                    '& .MuiListItemText-primary': {
                      fontSize: '12.7px',
                      fontWeight: '400',
                      textWrap: 'wrap',
                      color: '#1c1939',
                    },
                  }}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}

        <FormControl
          sx={[styles.productListingDropDown, styles.dropdownBgWhite]}>
          <Autocomplete
            noOptionsText="No options"
            disableCloseOnSelect
            data-testid="test-product-dropdown"
            onChange={(e, value) => handleProductSelect(value)}
            value={
              selectedProducts.length > 0
                ? filter?.filter((item: any) =>
                  selectedProducts.includes(item.productId),
                )
                : []
            }
            key="product"
            sx={{
              '& .MuiAutocomplete-input': {
                width: '2px !important',
                minWidth: '2px !important',
                paddingLeft: '1px !important',
              },
              '& .MuiOutlinedInput-root': {
                paddingRight: '30px !important',
              },
              '& .MuiInputLabel-formControl': {
                fontSize: '12.7px !important',
              },
              '& .MuiAutocomplete-endAdornment svg': {
                fill: '#322b7c',
              },
            }}
            multiple
            options={filter ?? []}
            getOptionLabel={(option: any) => option.productName}
            renderOption={(props, option, {selected}) => (
              <li
                {...props}
                key={option.productId}
                style={{
                  fontSize: '12.7px',
                  padding: '0px',
                  color: '##1c1939',
                  paddingBottom: '2px',
                  wordBreak: 'break-all',
                }}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{marginRight: 0}}
                  checked={selected}
                />
                {option.productName}
              </li>
            )}
            renderInput={(params: any) => (
              <TextField
                {...params}
                size="small"
                placeholder={strings.zho.product}
                // className={classes2.textField}
                label={strings.zho.product}
                InputProps={{
                  ...params.InputProps,
                  onFocus: () => handleInputFocus(2),
                  onBlur: handleInputBlur,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                  sx: {
                    '& .MuiInputBase-input::placeholder': {
                      fontSize: '12.7px !important',
                      opacity:
                        selectedProducts.length !== 0 &&
                        isFocused.focus &&
                        isFocused.id === 2
                          ? 0.5
                          : 0,
                    },
                  },
                }}
              />
            )}
            renderTags={(value, getTagProps) => {
              if (isFocused.focus && isFocused.id === 2) return null; // Hide tags when input is focused
              if (value.length === 0) return null;

              const firstLabel = value[0]?.productName?.slice(0, 20) + '...';
              const additionalCount =
                value?.length > 1 ? ` ${value?.length} Products selected` : '';

              if (value?.length === 1) {
                return (
                  <span style={{fontSize: '12.7px', paddingLeft: '8px'}}>
                    {firstLabel}
                  </span>
                );
              } else {
                return (
                  <span style={{fontSize: '12.7px', paddingLeft: '8px'}}>
                    {additionalCount}
                  </span>
                );
              }
            }}
          />
        </FormControl>

        <Button
          data-testid="button-go"
          sx={styles.goButton}
          variant="outlined"
          onClick={handleGoButtonClick}
          size="medium">
          {strings.zho.go}
        </Button>
      </Stack>
      <Container
        maxWidth={false}
        sx={{position: 'relative', minHeight: '100px'}}>
        {productListData === null ? (
          <Box sx={styles.loader}>
            <CircularProgress />
          </Box>
        ) : (
          <Box className={tableStyles.container} id={'emptyStyledGrid'}>
            {productListData?.productList?.length > 0 ? (
              <DataGrid
                disableVirtualization
                rows={productListData?.productList}
                sx={{
                  '& .MuiDataGrid-row:hover': {},
                  border: 0,
                  padding: '0px 2px 0px 2px',
                  ...gridStyles,
                }}
                disableSelectionOnClick
                columns={columns}
                initialState={{
                  pagination: {
                    pageSize: 10,
                  },
                }}
                getRowId={row => row.productId}
                rowsPerPageOptions={[10, 20, 50, 100]}
              />
            ) : (
              <Box sx={{textAlign: 'center', marginTop: 10}}>
                <Label
                  title={'No data available'}
                  variant={'h1'}
                  hideToolTip
                  size={20}
                />
              </Box>
            )}
          </Box>
        )}
      </Container>
    </>
  );
};
