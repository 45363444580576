import {strings} from '@app/common/strings';
import {
  Button,
  FormControl,
  Grid,
  Autocomplete,
  TextField,
  Typography,
  CircularProgress,
  Checkbox,
} from '@mui/material';
import {DatePicker, LocalizationProvider} from '@mui/x-date-pickers';
import Scrollbar from 'react-scrollbars-custom';
import {useStyles} from '@app/screens/zho/styles';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {useCallback, useEffect, useState} from 'react';
import dayjs from 'dayjs';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {
  fetchCustomerDataCreator,
  fetchHqDataCreator,
  fetchMrDataCreator,
  fetchStateDataCreator,
  fetchSummaryTableDataCreator,
  fetchSummaryTotalDataCreator,
  hoDashboardActions,
} from '../../pages/ho-admin/redux-api/slice';
import {
  fcDashboardActions,
  fetchDivisionDataCreator,
} from '../../pages/fc-admin/redux-api/slice';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import {updateSummaryPayload} from '../../pages/ho-admin/redux/orderStore';
import {ToasterService} from '@app/services';
import {ToastType} from '@app/services/toaster';

interface Division {
  divisionId: number;
  divisionName: string;
}

interface StateFilter {
  stateId: number;
  stateName: string;
}

interface HqFilter {
  hqId: number;
  hqName: string;
}

interface MrFilter {
  mrId: number;
  hqId: number;
  mrName: string;
  sapCode: string;
}
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FilterSummary = ({userLoginInfo}: any) => {
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null | string>(
    dayjs().startOf('month').format('YYYY-MM-DD'),
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null | string>(
    dayjs().format('YYYY-MM-DD'),
  );
  const [division, setDivision] = useState<number | string>(
    userLoginInfo?.division[0]?.id || '',
  );
  const [stateId, setStateId] = useState<number | string>('');
  const [hqName, setHqName] = useState<number[]>([]);
  const [mrName, setMrName] = useState<number[]>([]);
  const [customer, setCustomer] = useState<number[]>([]);
  const [isFocused, setIsFocused] = useState<{
    focus: boolean;
    id: number | null;
  }>({
    focus: false,
    id: null,
  });
  const classes = useStyles();
  const dispatch = useDispatch();

  const summaryPayload = useSelector(
    (state: RootStateOrAny) => state.hoOrderStore.value.summaryPayload,
  );

  const divisionData = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.divisionData,
  );

  const divisionLoading = useSelector(
    (state: RootStateOrAny) => state.fcDashBoard.divisionLoading,
  );

  const stateData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.stateData,
  );

  const stateLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.stateLoading,
  );

  const hqData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.hqData,
  );

  const hqLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.hqLoading,
  );

  const mrData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.mrData,
  );

  const mrLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.mrLoading,
  );

  const customerData = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.customerData,
  );

  const customerLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.customerLoading,
  );

  const currentSubTab = useSelector(
    (state: RootStateOrAny) => state.tabNames.value.subTabSwitch,
  );

  const handleInputFocus = (id: number) => {
    setIsFocused({
      focus: true,
      id,
    });
  };

  const handleInputBlur = () => {
    setIsFocused({
      focus: false,
      id: null,
    });
  };

  const preventDefaultHandleBill = useCallback(e => e.preventDefault(), []);
  const renderInputBill = useCallback(function (params: any) {
    return (
      <TextField
        {...params}
        helperText={null}
        onKeyDown={preventDefaultHandleBill}
        size="small"
      />
    );
  }, []);

  const handleDateChange = (date: dayjs.Dayjs | null | any, type: string) => {
    if (type === 'start') {
      if (date) {
        const formattedStartDate = date.format('YYYY-MM-DD');
        setStartDate(formattedStartDate);
      }
    } else {
      if (date) {
        const formattedEndDate = date.format('YYYY-MM-DD');
        setEndDate(formattedEndDate);
      }
    }
  };

  const handleFilterChange = (event: any, type: string, dropValue: any) => {
    const {
      target: {value},
    } = event;

    let selectedIds: any;
    if (type === 'division') {
      const divisionId = dropValue ? parseInt(dropValue.divisionId) : '';
      setDivision(divisionId);
      setCustomer([]);
      dispatch(hoDashboardActions.setCustomerData([]));
      if (
        divisionId !== '' &&
        divisionId !== null &&
        divisionId !== undefined
      ) {
        dispatch(fetchCustomerDataCreator(divisionId));
      }
    } else if (type === 'state') {
      const stateId = dropValue ? parseInt(dropValue.stateId) : '';
      setStateId(stateId);
      setHqName([]);
      dispatch(hoDashboardActions.setHqData([]));
      if (stateId !== '' && stateId !== null && stateId !== undefined) {
        dispatch(fetchHqDataCreator(stateId, 'summary'));
      }
    } else if (type === 'hq') {
      selectedIds = dropValue.map((item: any) => item.hqId);
      if (selectedIds.length === 0) {
        dispatch(hoDashboardActions.setMrData([]));
        setHqName([]);
        setMrName([]);
      } else {
        dispatch(fetchMrDataCreator(selectedIds, 'summary'));
        setHqName(selectedIds);
      }
    } else if (type === 'customer') {
      selectedIds = dropValue.map((item: any) => item.stockistId);
      setCustomer(selectedIds);
    } else {
      selectedIds = dropValue.map((item: any) => item.mrId);
      setMrName(selectedIds);
    }
  };

  const handleFilterApiCall = () => {
    let dateCheck = false;

    if (endDate && startDate) {
      if (endDate < startDate) {
        dateCheck = true;
        ToasterService.hideToaster();
        ToasterService.showToaster('Invalid Date Range!', ToastType.ERROR);
      }
    }

    if (!dateCheck) {
      dispatch(
        updateSummaryPayload({
          ...summaryPayload,
          currentStaffPositionId: userLoginInfo?.staffPositionId,
          customer: customer,
          mrStaffIds: mrName,
          state: stateId,
          division: division === '' ? [] : [division],
          hq: hqName,
          fromDate: startDate,
          toDate: endDate,
        }),
      );

      dispatch(
        fetchSummaryTotalDataCreator({
          ...summaryPayload,
          currentStaffPositionId: userLoginInfo?.staffPositionId,
          customer: customer,
          mrStaffIds: mrName,
          state: stateId,
          division: division === '' ? [] : [division],
          hq: hqName,
          fromDate: startDate,
          toDate: endDate,
        }),
      );

      dispatch(
        fetchSummaryTableDataCreator(
          {
            ...summaryPayload,
            currentStaffPositionId: userLoginInfo?.staffPositionId,
            customer: customer,
            mrStaffIds: mrName,
            state: stateId,
            division: division === '' ? [] : [division],
            hq: hqName,
            fromDate: startDate,
            toDate: endDate,
          },
          currentSubTab - 1,
        ),
      );
    }
  };

  useEffect(() => {
    dispatch(
      fetchDivisionDataCreator(userLoginInfo?.staffPositionId, 'summary'),
    );

    dispatch(fetchCustomerDataCreator(userLoginInfo?.division[0]?.id));

    dispatch(fetchStateDataCreator(userLoginInfo?.staffPositionId, 'summary'));
    return () => {
      document.body.style.overflow = 'auto';
      dispatch(fcDashboardActions.setDivisionData([]));
      dispatch(hoDashboardActions.setStateData([]));
      dispatch(hoDashboardActions.setHqData([]));
      dispatch(hoDashboardActions.setMrData([]));
      dispatch(hoDashboardActions.setCustomerData([]));
    };
  }, []);

  return (
    <>
      <div
        style={{
          background: 'none',
          marginTop: '0px',
          marginLeft: '20px',
          marginRight: '20px',
          padding: '0px',
        }}
        className={classes.toolbarRequestForm}>
        <Scrollbar
          style={{
            width: '100%',
            height: '89px',
          }}>
          <Grid
            sx={{paddingTop: '3px', background: 'none !important'}}
            container
            className={classes.filterGrid}
            spacing={1}>
            <Grid item xs sx={{flexBasis: '16%'}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Typography className={classes.FilterLabel}>
                  FROM DATE
                </Typography>
                <DatePicker
                  inputFormat="DD/MM/YYYY"
                  disableFuture={true}
                  label={strings.zho.fcAdmin.requestForm.filters.startDate}
                  className={classes.filterFromDate}
                  // PopperProps={DialogProps}
                  views={['year', 'month', 'day']}
                  value={startDate}
                  onChange={e => handleDateChange(e, 'start')}
                  renderInput={renderInputBill}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs sx={{flexBasis: '16%'}}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Typography className={classes.FilterLabel}>TO DATE</Typography>
                <DatePicker
                  disableFuture={true}
                  label={strings.zho.fcAdmin.requestForm.filters.endDate}
                  inputFormat="DD/MM/YYYY"
                  className={classes.filterToDate}
                  // PopperProps={DialogProps}
                  views={['year', 'month', 'day']}
                  value={endDate}
                  onChange={e => handleDateChange(e, 'end')}
                  renderInput={renderInputBill}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item sx={{flexBasis: '16%'}}>
              <FormControl fullWidth className={classes.dropdownBgWhite}>
                <Typography className={classes.FilterLabel}>
                  {strings.division}
                </Typography>
                <Autocomplete
                  value={
                    division
                      ? divisionData.find(
                          (item: any) => item.divisionId === division,
                        ) || null
                      : null
                  }
                  onChange={(e, value: any) =>
                    handleFilterChange(e, 'division', value)
                  }
                  noOptionsText="No options"
                  key={'search-hq'}
                  slotProps={{
                    popper: {
                      sx: {
                        zIndex: 1000,
                      },
                    },
                  }}
                  sx={{
                    minWidth: 180,
                    maxWidth: 180,
                    paddingRight: '0px !important',

                    '& .MuiAutocomplete-input': {
                      width: '1px !important',
                      minWidth: '1px !important',
                    },
                    '& .MuiInputLabel-formControl': {
                      fontSize: '11.7px !important',
                    },
                    '& .MuiAutocomplete-endAdornment svg': {
                      fill: '#322b7c',
                    },
                    '& .MuiAutocomplete-noOptions': {
                      zIndex: 1000,
                    },
                  }}
                  options={divisionData}
                  getOptionLabel={(option: Division) =>
                    option?.divisionName?.length > 13
                      ? option?.divisionName?.slice(0, 13) + '...'
                      : option?.divisionName
                  }
                  renderOption={(props, option: Division) => (
                    <li
                      {...props}
                      key={option.divisionId}
                      style={{
                        fontSize: '12.7px',
                        color: '##1c1939',
                        paddingBottom: '2px',
                        wordBreak: 'break-all',
                      }}>
                      {option.divisionName}
                    </li>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      size="small"
                      data-testid="test-division-dropdown"
                      placeholder={'Select Division'}
                      className={classes.textField}
                      label="Select Division"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {divisionLoading ? (
                              <CircularProgress
                                className={classes.filterLoader}
                                color="primary"
                                size={20}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '11px !important',
                          },
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item sx={{flexBasis: '16%'}}>
              <FormControl fullWidth className={classes.dropdownBgWhite}>
                <Typography className={classes.FilterLabel}>
                  {strings.State}
                </Typography>
                <Autocomplete
                  value={
                    stateData.find(
                      (item: StateFilter) => item.stateId === stateId,
                    ) || null
                  }
                  onChange={(e, value: any) =>
                    handleFilterChange(e, 'state', value)
                  }
                  noOptionsText="No options"
                  key={'search-state'}
                  slotProps={{
                    popper: {
                      sx: {
                        zIndex: 1000,
                      },
                    },
                  }}
                  sx={{
                    minWidth: 180,
                    maxWidth: 180,
                    paddingRight: '0px !important',

                    '& .MuiAutocomplete-input': {
                      width: '1px !important',
                      minWidth: '1px !important',
                    },
                    '& .MuiInputLabel-formControl': {
                      fontSize: '11.7px !important',
                    },
                    '& .MuiAutocomplete-endAdornment svg': {
                      fill: '#322b7c',
                    },
                  }}
                  options={stateData}
                  getOptionLabel={(option: StateFilter) =>
                    option?.stateName?.length > 13
                      ? option?.stateName?.slice(0, 13) + '...'
                      : option?.stateName
                  }
                  renderOption={(props: any, option: StateFilter) => (
                    <li
                      {...props}
                      key={option?.stateId}
                      style={{
                        fontSize: '12.7px',
                        color: '##1c1939',
                        paddingBottom: '2px',
                        wordBreak: 'break-all',
                      }}>
                      {option.stateName}
                    </li>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder={'Select State'}
                      className={classes.textField}
                      label="Select State"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {stateLoading ? (
                              <CircularProgress
                                className={classes.filterLoader}
                                color="primary"
                                size={20}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '11px !important',
                          },
                        },
                      }}
                    />
                  )}
                  renderTags={(value: any) => {
                    if (!value) return null;
                    const firstLabel =
                      value.stateName?.length > 13
                        ? value.stateName?.slice(0, 13) + '...'
                        : value.stateName;
                    return (
                      <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                        {firstLabel}
                      </span>
                    );
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item sx={{flexBasis: '16%'}}>
              <FormControl fullWidth className={classes.dropdownBgWhite}>
                <Typography
                  textTransform="uppercase"
                  className={classes.FilterLabel}>
                  {strings.hq}
                </Typography>
                <Autocomplete
                  disableCloseOnSelect
                  value={hqData.filter((item: HqFilter) =>
                    hqName.includes(item.hqId),
                  )}
                  onChange={(e, value: any) =>
                    handleFilterChange(e, 'hq', value)
                  }
                  noOptionsText="No options"
                  key={'search-hq'}
                  slotProps={{
                    popper: {
                      sx: {
                        zIndex: 1000,
                      },
                    },
                  }}
                  sx={{
                    minWidth: 180,
                    maxWidth: 180,
                    paddingRight: '0px !important',

                    '& .MuiAutocomplete-input': {
                      width: '1px !important',
                      minWidth: '1px !important',
                    },
                    '& .MuiInputLabel-formControl': {
                      fontSize: '11.7px !important',
                    },
                    '& .MuiAutocomplete-endAdornment svg': {
                      fill: '#322b7c',
                    },
                  }}
                  multiple
                  options={hqData}
                  getOptionLabel={(option: any) => option.hqName}
                  renderOption={(props, option, {selected}) => (
                    <li
                      {...props}
                      key={option.hqId}
                      style={{
                        fontSize: '12.7px',
                        padding: '0px',
                        color: '##1c1939',
                        paddingBottom: '2px',
                        wordBreak: 'break-all',
                      }}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={
                          option.hqId === 'select_all'
                            ? hqName.length === hqData.length
                            : selected
                        }
                      />
                      {option.hqName}
                    </li>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder={'Select HQ'}
                      className={classes.textField}
                      label="Select HQ"
                      InputProps={{
                        ...params.InputProps,
                        onFocus: () => handleInputFocus(2),
                        onBlur: handleInputBlur,
                        endAdornment: (
                          <>
                            {hqLoading ? (
                              <CircularProgress
                                className={classes.filterLoader}
                                color="primary"
                                size={20}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '11px !important',
                            opacity:
                              hqName.length !== 0 &&
                              isFocused.focus &&
                              isFocused.id === 2
                                ? 0.5
                                : 0,
                          },
                        },
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) => {
                    if (isFocused.focus && isFocused.id === 2) return null; // Hide tags when input is focused
                    if (value.length === 0) return null;

                    const firstLabel = value[0]?.hqName?.slice(0, 13) + '...';
                    const additionalCount =
                      value?.length > 1
                        ? ` ${value?.length} HQ's selected`
                        : '';

                    if (value?.length === 1) {
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                          {firstLabel}
                        </span>
                      );
                    } else {
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                          {additionalCount}
                        </span>
                      );
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item sx={{flexBasis: '16%'}}>
              <FormControl
                sx={{height: '34px !important'}}
                fullWidth
                className={classes.dropdownBgWhite}>
                <Typography
                  textTransform="uppercase"
                  className={classes.FilterLabel}>
                  {strings.zho.mr}
                </Typography>
                <Autocomplete
                  noOptionsText="No options"
                  disableCloseOnSelect
                  data-testid="test-product-dropdown"
                  onChange={(e, value) => handleFilterChange(e, 'mr', value)}
                  value={mrData?.filter((item: MrFilter) =>
                    mrName.includes(item.mrId),
                  )}
                  key={'search mr'}
                  slotProps={{
                    popper: {
                      sx: {
                        zIndex: 1000,
                      },
                    },
                  }}
                  filterOptions={(options, {inputValue}) => {
                    return options.filter(
                      option =>
                        option.mrName
                          .toLowerCase()
                          .includes(inputValue.toLowerCase()) ||
                        option.sapCode
                          .toString()
                          .includes(inputValue.toLowerCase()),
                    );
                  }}
                  sx={{
                    minWidth: 180,
                    maxWidth: 180,
                    paddingRight: '0px !important',
                    '& .MuiAutocomplete-input': {
                      width: '2px !important',
                      minWidth: '2px !important',
                      paddingLeft: '1px !important',
                    },
                    '& .MuiOutlinedInput-root': {
                      paddingRight: '0px !important',
                    },
                    '& .MuiInputLabel-formControl': {
                      fontSize: '11.7px !important',
                    },
                    '& .MuiAutocomplete-endAdornment svg': {
                      fill: '#322b7c',
                    },
                  }}
                  multiple
                  options={mrData}
                  getOptionLabel={(option: MrFilter) =>
                    option?.mrName?.length > 13
                      ? option.mrName?.slice(0, 13) + '...'
                      : option.mrName
                  }
                  renderOption={(props, option: any, {selected}, index) => (
                    <li
                      {...props}
                      key={+option.mrId}
                      style={{
                        fontSize: '12.7px',
                        padding: '0px',
                        color: '##1c1939',
                        paddingBottom: '2px',
                        wordBreak: 'break-all',
                      }}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={
                          option.mrId === 'select_all'
                            ? mrName.length === mrData.length
                            : selected
                        }
                      />
                      {`${option.mrName} (${option.sapCode})`}
                    </li>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder={'Select MR'}
                      className={classes.textField}
                      label="Select MR"
                      InputProps={{
                        ...params.InputProps,
                        onFocus: () => handleInputFocus(3),
                        onBlur: handleInputBlur,
                        endAdornment: (
                          <>
                            {mrLoading ? (
                              <CircularProgress
                                className={classes.filterLoader}
                                color="primary"
                                size={20}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '11px !important',
                            opacity:
                              hqName.length !== 0 &&
                              isFocused.focus &&
                              isFocused.id === 3
                                ? 0.5
                                : 0,
                          },
                        },
                      }}
                    />
                  )}
                  renderTags={(value: any, getTagProps) => {
                    if (isFocused.focus && isFocused.id === 3) return null; // Hide tags when input is focused
                    if (value.length === 0) return null;
                    const firstLabel = value[0]?.mrName?.slice(0, 13) + '...';
                    const additionalCount =
                      value?.length > 1 ? ` ${value.length} Mr's selected` : '';
                    if (value.length === 1) {
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                          {firstLabel}
                        </span>
                      );
                    } else {
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                          {additionalCount}
                        </span>
                      );
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item sx={{flexBasis: '16%'}}>
              <FormControl
                sx={{height: '34px !important'}}
                fullWidth
                className={classes.dropdownBgWhite}>
                <Typography
                  textTransform="uppercase"
                  className={classes.FilterLabel}>
                  {strings.zho.customer}
                </Typography>
                <Autocomplete
                  noOptionsText="No options"
                  data-testid="test-customer-dropdown"
                  disableCloseOnSelect
                  onChange={(e, value) =>
                    handleFilterChange(e, 'customer', value)
                  }
                  value={customerData.filter((item: any) =>
                    customer.includes(item.stockistId),
                  )}
                  filterOptions={(options, {inputValue}) => {
                    return options.filter(
                      option =>
                        option.stockistName
                          .toLowerCase()
                          .includes(inputValue.toLowerCase()) ||
                        option.stockistId
                          .toLowerCase()
                          .includes(inputValue.toLowerCase()),
                    );
                  }}
                  key={'search-Customer'}
                  slotProps={{
                    popper: {
                      sx: {
                        zIndex: 1000,
                      },
                    },
                  }}
                  sx={{
                    minWidth: 200,
                    maxWidth: 200,
                    paddingRight: '0px !important',
                    '& .MuiAutocomplete-input': {
                      width: '2px !important',
                      minWidth: '2px !important',
                      paddingLeft: '1px !important',
                    },
                    '& .MuiOutlinedInput-root': {
                      paddingRight: '0px !important',
                    },
                    '& .MuiInputLabel-formControl': {
                      fontSize: '11.7px !important',
                    },
                    '& .MuiAutocomplete-endAdornment svg': {
                      fill: '#322b7c',
                    },
                  }}
                  multiple
                  options={customerData}
                  getOptionLabel={(option: any) =>
                    option?.stockistName?.length > 13
                      ? option.stockistName?.slice(0, 13) + '...'
                      : option.stockistName
                  }
                  renderOption={(props, option: any, {selected}, index) => (
                    <li
                      {...props}
                      key={+option.stockistCode}
                      style={{
                        fontSize: '12.7px',
                        padding: '0px',
                        color: '##1c1939',
                        paddingBottom: '2px',
                        wordBreak: 'break-all',
                      }}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={
                          option.stockistId === 'select_all'
                            ? customer.length === customerData.length
                            : selected
                        }
                      />
                      {`${option?.stockistName}, (${option?.stockistId}) `}
                    </li>
                  )}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      size="small"
                      placeholder={'Customer'}
                      className={classes.textField}
                      label="Customer"
                      InputProps={{
                        ...params.InputProps,
                        onFocus: () => handleInputFocus(3),
                        onBlur: handleInputBlur,
                        endAdornment: (
                          <>
                            {customerLoading ? (
                              <CircularProgress
                                className={classes.filterLoader}
                                color="primary"
                                size={20}
                              />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                        sx: {
                          '& .MuiInputBase-input::placeholder': {
                            fontSize: '11px !important',
                            opacity:
                              customer.length !== 0 &&
                              isFocused.focus &&
                              isFocused.id === 3
                                ? 0.5
                                : 0,
                          },
                        },
                      }}
                    />
                  )}
                  renderTags={(value: any, getTagProps) => {
                    if (isFocused.focus && isFocused.id === 3) return null; // Hide tags when input is focused
                    if (value.length === 0) return null;
                    const firstLabel =
                      value[0]?.stockistName?.slice(0, 13) + '...';
                    const additionalCount =
                      value?.length > 1
                        ? ` ${value.length} Customers's selected`
                        : '';
                    if (value.length === 1) {
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                          {firstLabel}
                        </span>
                      );
                    } else {
                      return (
                        <span style={{fontSize: '11px', paddingLeft: '8px'}}>
                          {additionalCount}
                        </span>
                      );
                    }
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Scrollbar>
        <Button
          onClick={handleFilterApiCall}
          data-testid="goButton"
          variant="contained"
          component="span"
          className={classes.requestFormgo}
          sx={{
            maxWidth: '10px',
            fontSize: '12.7px',
            marginTop: '10px !important',
            marginLeft: '12px !important',
            textTransform: 'capitalize',
          }}
          size="medium">
          {strings.go}
        </Button>
      </div>
    </>
  );
};

export default FilterSummary;
