import {routeSelector} from '@app/router/redux';
import {Button, CircularProgress} from '@mui/material';
import * as Excel from 'exceljs';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
const styles = {
  ledgerButton: {
    textTransform: 'none',
    height: 30,
    left: 5,
    width: 150,
  },
  challanButton: {
    marginRight: 1,
    textTransform: 'none',
    borderRadius: '20px',
  },
};

const ExcelReportDownloadCommon = ({
  data = [],
  fileName,
  Header,
  buttonTitle,
  disableButton,
  onClick,
  resetFunction,
  division,
  staff,
  sap,
  designation,
  hqName,
  state,
  subordinate,
  searchType,
  searchValue,
  user,
}: any) => {
  const navbarComponentNameSelector = useSelector(
    routeSelector.getNavbarComponentName(),
  );
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (loader && data.length > 0) {
      exportDataToExcel();
    }
  }, [data, loader]);

  const fitToColumnExcelJs = (worksheet: any, data: any[]) => {
    let index = 1;
    for (const property in data[0]) {
      const maxColumnWidth = Math.max(
        property ? property.toString().length * 10 : 0,
        ...data.map(obj =>
          obj[property] ? obj[property].toString().length * 10 : 0,
        ),
      );
      if (index === 7 || index === 8) {
        worksheet.getColumn(index).width = 30;
        index++;
      } else {
        worksheet.getColumn(index).width =
          maxColumnWidth < 110 ? 20 : maxColumnWidth / 7;
        index++;
      }
    }
  };

  const base64DownloaderMiscWeb = (excelBuffer: BlobPart) => {
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const linkSource = URL.createObjectURL(blob);
    const downloadLink = document?.createElement('a')
      ? document?.createElement('a')
      : {href: '', download: '', click: () => true};
    downloadLink.href = linkSource;
    downloadLink.download = `${fileName}.xlsx`;
    downloadLink.click();
    resetFunction();
    return null;
  };

  const CallExcelData = () => {
    setLoader(true);
    onClick();
  };
  const HeadersArr = Header?.map((item: any) => item.title);
  const exportDataToExcel = async () => {
    let wb = new Excel.Workbook();
    let ws = wb.addWorksheet('REPORT');

    // Helper function to add and merge a row
    const addMergedRow = (label: string, value: string, rowIndex: number) => {
      let row = ws.addRow([label, value]); // Add label and value in separate cells with background color
      // const cell = ws.getCell(`A${rowIndex}`);
      if (rowIndex !== 0) {
        row.eachCell(cell => {
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'FFFF00'},
          };
          cell.font = {bold: true};
          cell.border = {
            top: {style: 'thin'},
            left: {style: 'thin'},
            bottom: {style: 'thin'},
            right: {style: 'thin'},
          }; // Adding border to each cell
        });
      }

      row.getCell(1).alignment = {horizontal: 'left'}; // Align the first cell (label) to the left
    };

    // Add header rows based on navbarComponentNameSelector
    const addHeaderRows = () => {
      if (navbarComponentNameSelector === 'LedgerReport') {
        addMergedRow('', '', 0);
        addMergedRow(`Staff Name:`, staff, 1);
        addMergedRow(`SAP Code:`, sap, 2);
        addMergedRow(`Division:`, division, 3);
        addMergedRow(`Designation Name:`, designation, 4);
      } else {
        addMergedRow('', '', 0);
        addMergedRow(`Search Type:`, searchType, 1);
        addMergedRow(`Search Value:`, searchValue, 2);
        addMergedRow(`User:`, user, 3);
      }
    };

    // Add additional rows
    const addAdditionalRows = () => {
      if (state) {
        addMergedRow(`State Name:`, state, ws.actualRowCount + 1);
      }
      if (hqName) {
        addMergedRow(`HQ Name:`, hqName, ws.actualRowCount + 1);
      }

      if (subordinate) {
        addMergedRow(`Subordinate's Name:`, subordinate, ws.actualRowCount + 1);
      }
      if (navbarComponentNameSelector === 'LedgerReport') {
        // Add an empty row
        ws.addRow([]);
      }
    };

    // Add header and additional rows
    addHeaderRows();
    addAdditionalRows();

    // Prepare and add excel data rows
    const formatCellValue = (itemName: any, cellValue: string) => {
      itemName === 'transactionDate' ||
        itemName === 'voucherDate' ||
        itemName === 'grDate';
      {
        return cellValue?.split('T')[0];
      }

      return cellValue;
    };

    const prepareRowData = (rowData: {[x: string]: any}) => {
      return Header?.map((item: {name: string | number}) =>
        formatCellValue(item.name, rowData[item.name]),
      );
    };

    const prepareExcelData = () => {
      return data.map(prepareRowData);
    };

    const excelData = prepareExcelData();
    ws.addRow([`${fileName}`, '', '', '', '', '', '', '']);

    // Add the headers row
    var headerRow = ws.addRow(HeadersArr);

    // For adding background on list header
    headerRow.eachCell(cell => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FFFF00'},
      };
      cell.font = {bold: true};
      cell.border = {
        top: {style: 'thin'},
        left: {style: 'thin'},
        bottom: {style: 'thin'},
        right: {style: 'thin'},
      }; // Adding border to each cell
    });

    // Add the remaining data rows
    excelData.forEach((dataRow: any) => {
      ws.addRow(dataRow);
    });

    // Function to apply alternating colors
    const applyAlternatingColors = (worksheet: any, columnIndex: number) => {
      let currentColor = 'FFC0C0C0'; // Gray color
      let previousValue: null = null;
      worksheet.eachRow(
        {includeEmpty: false},
        (row: any, rowNumber: number) => {
          if (navbarComponentNameSelector === 'LedgerReport') {
            if (rowNumber > 9) {
              // Skip header rows
              const cellValue = row.getCell(columnIndex).value;
              if (cellValue !== previousValue) {
                currentColor =
                  currentColor === 'FFC0C0C0' ? 'FFADD8E6' : 'FFC0C0C0'; // Alternate colors
              }
              row.eachCell((cell: any) => {
                cell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: {argb: currentColor},
                };
              });

              previousValue = cellValue;
            }
          }
        },
      );
    };

    // Apply alternating colors to the specified column (e.g., column 1)
    applyAlternatingColors(ws, 2); // Change columnIndex as per your requirement
    const finalExcelData = [
      [`${fileName}`, '', '', '', '', '', '', ''],
      [...HeadersArr],
      ...excelData,
    ];

    ws.addRows(finalExcelData);

    ws.mergeCells(1, 1, 1, 8);
    fitToColumnExcelJs(ws, excelData);

    // Write to buffer and download
    wb.xlsx.writeBuffer().then(async excelBuffer => {
      setLoader(false);
      base64DownloaderMiscWeb(excelBuffer);
    });
  };

  return (
    <Button
      data-testid={'Report-download'}
      variant="contained"
      size="small"
      onClick={CallExcelData}
      disabled={disableButton}
      sx={
        navbarComponentNameSelector === 'LedgerReport'
          ? styles.ledgerButton
          : styles.challanButton
      }>
      {' '}
      {loader ? <CircularProgress color="inherit" size={20} /> : buttonTitle}
    </Button>
  );
};

export default ExcelReportDownloadCommon;
