import {Button, CircularProgress, Typography} from '@mui/material';
import {Box, Stack} from '@mui/system';
import {ErrorTableStyles as styles} from '@app/screens/zho/pages/fc-admin/manage-product-status/components/errorTableStyles';
import {useStyles as tableStyle} from '@app/screens/zho/components/grid-table/styles';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {strings} from '@app/common/strings';
import {RootStateOrAny, useDispatch, useSelector} from 'react-redux';
import {useEffect} from 'react';
import {hoDashboardActions} from '../../redux-api';

const ApprovedDateGrid = () => {
  const approvedTableList = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderSyncMsg,
  );

  const approvedTableLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderSyncLoading,
  );

  const tableStyles = tableStyle();

  const columns: GridColDef[] = [
    {
      field: 'orderNumber',
      headerName: strings.zho.hoAdmin.dashboard.tableHeading.orderNo,
      headerClassName: 'boldHeader',
      width: 190,
      sortable: false,
      valueGetter: params => params?.row?.orderNumber || '---',
    },
    {
      field: 'message',
      headerName: strings.zho.hoAdmin.dashboard.modalPopup.sapRemarks,
      headerClassName: 'boldHeader',
      width: 320,
      sortable: false,
      valueGetter: params => params?.row?.message || '---',
    },
  ];

  return (
    <>
      {!approvedTableLoading ? ( // First condition: loading state
        approvedTableList?.orders?.length > 0 ? ( // Second condition: data present
          <Box className={tableStyles.container} sx={{padding: '0 0 0 10px'}}>
            <DataGrid
              sx={[
                styles.errorTable,
                {
                  '& .MuiTablePagination-actions': {
                    display: 'none', // Hide the pagination arrow buttons
                  },
                  '& .MuiTablePagination-toolbar': {
                    paddingRight: '65px',
                  },
                },
              ]}
              rows={approvedTableList?.orders}
              disableSelectionOnClick
              columns={columns}
              pageSize={10}
              getRowId={row => row.orderNumber || Math.random()}
            />
          </Box>
        ) : (
          <Typography
            sx={{
              textAlign: 'center',
              padding: '20px',
              fontWeight: 400,
              fontSize: '12.7px',
            }}>
            {strings.noRecordFound}
          </Typography>
        )
      ) : (
        <Box
          className={tableStyles.loader}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: 3,
          }}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

const OrderApproveTable = ({
  setApproveModalOpen,
  approveModalOpen,
  syncApproveChecking,
}: any) => {
  const tableStyles = tableStyle();
  const dispatch = useDispatch();
  const handleToggleModal = () => {
    setApproveModalOpen(!approveModalOpen);
    dispatch(hoDashboardActions.setOrderSyncCompleted([]));
  };

  const approvedTableList = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderSyncMsg,
  );

  const approvedTableLoading = useSelector(
    (state: RootStateOrAny) => state.hoDashBoard.orderSyncLoading,
  );

  const apiErrorCloseModal = useSelector(
    (state: RootStateOrAny) => state.hoOrderStore.value.CloseOrderPopup,
  );

  useEffect(() => {
    if (apiErrorCloseModal) {
      setApproveModalOpen(!approveModalOpen);
    }
    return () => {
      dispatch(hoDashboardActions.setOrderSyncCompleted([]));
    };
  }, [apiErrorCloseModal]);

  const columns: GridColDef[] = [
    {
      field: 'orderNumber',
      headerName: strings.zho.hoAdmin.dashboard.tableHeading.orderNo,
      headerClassName: 'boldHeader',
      width: 190,
      sortable: false,
      valueGetter: params => params?.row?.orderNumber || '---',
    },
    {
      field: 'message',
      headerName: strings.zho.hoAdmin.dashboard.modalPopup.sapRemarks,
      headerClassName: 'boldHeader',
      width: 320,
      sortable: false,
      valueGetter: params => params?.row?.message || '---',
    },
  ];

  return (
    <>
      <Box
        sx={[
          styles.modalSection,
          styles.modalHeader,
          {display: 'flex', gap: 2, flexWrap: 'wrap'},
        ]}>
        {syncApproveChecking.approveCheckList.length === 0 && (
          <Stack direction="column">
            <Typography sx={{fontSize: '12.7px'}}>
              {strings.zho.hoAdmin.dashboard.modalPopup.totalOrder}{' '}
              {approvedTableList?.totalCount}
            </Typography>
            <Typography sx={{fontSize: '12.7px'}}>
              {syncApproveChecking.type === 'sync'
                ? strings.zho.hoAdmin.dashboard.modalPopup.successfullySynced
                : strings.zho.hoAdmin.dashboard.modalPopup
                    .successfullyApproved}{' '}
              {approvedTableList?.successfullCount}
            </Typography>
          </Stack>
        )}
      </Box>
      <Box sx={[styles.modalSection, {padding: '0px'}]}>
        {!syncApproveChecking.isAllSynced &&
        (syncApproveChecking.approveCheckList.length === 0 ||
          syncApproveChecking.approveCheckList.length === null) ? (
          <ApprovedDateGrid />
        ) : (
          <Box className={tableStyles.container} sx={{padding: '0 0 0 10px'}}>
            <DataGrid
              sx={[
                styles.errorTable,
                {
                  '& .MuiTablePagination-actions': {
                    display: 'none', // Hide the pagination arrow buttons
                  },
                  '& .MuiTablePagination-toolbar': {
                    paddingRight: '65px',
                  },
                },
              ]}
              rows={syncApproveChecking.approveCheckList}
              disableSelectionOnClick
              columns={columns}
              pageSize={10}
              getRowId={row => row?.orderNumber || Math.random()}
            />
          </Box>
        )}
      </Box>
      <Box
        marginTop={approvedTableLoading ? '6rem' : '0rem'}
        sx={[styles.modalSection, styles.modalFooter]}>
        <Button
          data-testid="close-errorTable"
          variant="contained"
          disabled={approvedTableLoading}
          sx={{
            fontSize: '12.7px',
            textTransform: 'capitalize',
          }}
          onClick={handleToggleModal}>
          {strings.zho.close}
        </Button>
      </Box>
    </>
  );
};

export default OrderApproveTable;
